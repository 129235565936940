<template>
  <app-echart-doughnut
    class="w-100"
    v-bind="{
      source,
      color,
      type,
      isNegative
    }"
  />
</template>

<script>
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  components: {
    AppEchartDoughnut,
  },
  props: {
    type: {
      type: String,
      validator(type) {
        return type === 'delivery' || type === 'cost'
      },
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    colors: {
      type: Array,
      default() {
        return ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
      },
    },
  },
  computed: {
    source() {
      if (this.type === 'delivery') {
        return this.items.reduce(($series, { order_count, order_source_name }) => {
          const value = Math.abs(parseInt(order_count, 10))

          $series.push({ product: order_source_name, value })

          return $series
        }, [])
      } else {
        return this.items.reduce(($series, { sum, name }) => {
          const value = Math.abs(parseInt(sum, 10))

          $series.push({ product: name, value })
          return $series
        }, [])
      }
    },
    color() {
      if (this.type === 'delivery') {
        return this.items.map(({ color }) => {
          if (color) {
            return color
          } else {
            return 'green'
          }
        })
      } else {
        return this.items.map(({ sum }, index) => {
          if (sum < 0) {
            return 'red'
          } else {
            return [this.colors[index]]
          }
        })
      }
    },
    isNegative() {
      return this.items.filter(item => item.sum).some(({ sum }) => parseInt(sum, 10) < -1)
    },
  },
}
</script>

<style lang="scss">
.card-body {
    position: relative;
    .pie-text {
        width: 105px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 44%;
        bottom: 0;
    }
}
</style>
