<template>
  <e-charts
    ref="line"
    autoresize
    :options="option"
    theme="theme-color"
    type
    isNegative
  />
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
  },
  props: {
    isNegative: {
      type: Boolean,
      default() {
        return false
      },
    },
    source: {
      type: Array,
      default() {
        return null
      },
    },
    color: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: String,
      require: true,
    },
    formatter: {
      type: Function,
      default() {
        return (...props) => {
          if (this.type === 'delivery') {
            return `${props[0].name}: ${props[0].data.value} (${props[0].percent}%)`
          } else {
            const value = props[0].name === 'Чистая прибыль' && this.isNegative ? `-${props[0].data.value}` : `${props[0].data.value}`
            return `${props[0].name}: ${value}`
          }
        }
      },
    },
  },
  data() {
    return {
      option: {
        color: this.color,
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: this.formatter(),
        },
        legend: {
          top: '5%',
          left: 'center',
          itemGap: 10,
          padding: 0,
          //itemHeight: this.source.length / '14',
          itemHeight: this.source.length,
        },
        dataset: {
          source: this.source,
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            label: {
              show: false,
            },
          },
        ],
      },
    }
  },
  watch: {
    source(source) {
      this.option.dataset.source = source
    },
    color(color) {
      this.option.color = color
    },
  },
}
</script>
